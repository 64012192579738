html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.justify-content-center {
  justify-content: center;
}

.main-content-card {
  background: #eee;
  box-shadow: 0px 6px 10px 0px #bbb;
  border-radius: 3px;
  margin: 2rem;
}

.content-card {
  background: #eee;
  box-shadow: 0px 6px 10px 0px #bbb;
  border-radius: 3px;
}

.fill-available-width {
  width: 100%;
}

.drop-shadow {
  box-shadow: 0px 4px 10px 0px #444;
}

.space-between {
  justify-content: space-between;
}

.mobile-small-font {
  font-size: 0.85rem;
}

.w-33 {
  width: 33.3%;
}

.align-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.maxw-50-view {
  max-width: 50vw;
}

.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.align-self-flex-end {
  align-self: flex-end;
}

.flex-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.object-fit-cover {
  object-fit: cover;
  height: 10vw;
  width: 10vw;
}

@media (max-width: 992px) {
  .object-fit-cover {
    height: 20vw;
    width: 20vw;
  }
}
