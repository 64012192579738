.select-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(28, 28, 28, .7);
}

.select-modal-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 10px;
}

.select-modal-title {
  font-family: roc-grotesk, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}

.select-modal-search {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #464646;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  flex-grow: 1;
}

.select-modal-options-container {
  background: #464646;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 210px;
  overflow-y: scroll;
}

.select-modal-option:hover {
  color: #09C053;
  cursor: pointer;
}

.select-modal-option.selected {
  color: #09C053;
  font-weight: 700;
}

.select-modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exit-modal-button:hover {
  cursor: pointer;
  color: #E74040;
}

.select-modal-button {
  margin-top: 20px;
  font-family: roc-grotesk, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 29px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: #09C053;
  padding: 11px 12px 10px;
  border-radius: 15px;
}

.select-modal-button:hover {
  cursor: pointer;
  text-decoration: underline;
  background: linear-gradient(rgba(0,0,0, .1), rgba(0,0,0,.1)), #09C053;
}