.likert {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0 0 35px;
  display: block;
  border-bottom: 2px solid #efefef;
}
.likert:last-of-type {
  border-bottom: 0;
}
.likert:before {
  content: "";
  position: relative;
  top: 8px;
  left: 10%;
  display: block;
  background-color: #efefef;
  height: 4px;
  width: 80%;
}
.likert li {
  display: inline-block;
  width: 20%;
  text-align: center;
  vertical-align: top;
}
.likert li input[type="radio"] {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  margin-left: -6px;
}
.likert li label {
  width: 100%;
}
