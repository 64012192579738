/**
  NAVBAR STYLING
*/

@media (max-width: 768px) {
  .text-center-md {
    text-align: center !important;
  }

  .mobile-title-font {
    font-size: 9vw;
  }
}

.card-style {
  background: #353535;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
