.profile-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 75px;
}

.profile-header {
  font-family: roc-grotesk, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 92px;
  letter-spacing: 0.04em;
}

.top-tracks-artists-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 768px) {
  .profile-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .profile-header {
    font-size: 30px;
    line-height: 32px;
  }

}