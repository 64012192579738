
.playlists-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.playlists-header {
  font-family: roc-grotesk, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}

.playlists-nav-row {
  display: flex;
  gap: 10px;
}

.playlists-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.playlists-pagination {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #353535;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
}

.playlists-pagination-arrow:hover {
  color: #09C053;
  cursor: pointer;
}

.playlists-pagination-arrow.disabled {
  color: #8C8C8C;
}

.playlists-search {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #353535;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  flex-grow: 1;
}

.playlists-sort {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #353535;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
}

.playlist-row {
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.playlist-row.odd {
  background-color: #444444;
}

.playlist-image {
  border-radius: 5px;
}

.playlist-img-name {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.playlist-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  line-clamp: 2;
  text-overflow: ellipsis;
  line-height: 18px;
  font-size: 16px;
  max-width: 100%;
}

.edit-button {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  background: white;
  color: black;
  width: fit-content;
}

.edit-button:hover {
  cursor: pointer;
  background: linear-gradient(rgba(0,0,0, .2), rgba(0,0,0,.2)), white;
  text-decoration: underline;
}