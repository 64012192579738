.tracklist-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.track-row {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.track-row.odd {
  background: #444444;
}

.track-row-image-title {
  display: flex;
  gap: 10px;
  align-items: center;
}

.track-row-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.track-table-image {
  border-radius: 5px;
}

.track-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  line-clamp: 2;
  text-overflow: ellipsis;
  line-height: 18px;
  font-size: 16px;
  max-width: 100%;
}

.trash-button {
  display: flex;
  background: #E74040;
  border-radius: 15px;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
}
.trash-button:hover {
  cursor: pointer;
  text-decoration: underline;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #E74040;
}

.play-button, .plus-button {
  display: flex;
  background: #fff;
  border-radius: 15px;
  padding: 10px 12px;
  align-items: center;
  color: #000;
  justify-content: center;
}

.play-button:hover, .plus-button:hover {
  cursor: pointer;
  text-decoration: underline;
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #fff;
}


.tracklist-header {
  font-family: roc-grotesk, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}

.tracklist-button-row {
  display: flex;
  gap: 10px;
}

.tracklist-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tracklist-pagination {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #353535;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
}

.tracklist-pagination-arrow:hover {
  color: #09C053;
  cursor: pointer;
}

.tracklist-pagination-arrow.disabled {
  color: #8C8C8C;
}

.tracklist-search {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #353535;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  flex-grow: 1;
}

.tracklist-sort {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #353535;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
}